@import '../../main.scss';
@import '../../theme.scss';
@import './NavBarMobile.module.scss';

div.ant-drawer-header {
  background-color: $secondColor !important;
  border-radius: 0;
}

div.ant-drawer-title {
  color: #fff;
}

div.ant-drawer-body {
  background-color: $secondColor !important;
  color: #fff;
  height: calc(100vh - 55px);
}

.opened {
  @include layout(column, flex-start, center);
  font-family: "Montserrat";
  width: 100%;
  height: 100%;
}

.line {
  width: 70%;
  background-color: gray;
  height: 1px;
  opacity: 0.4;
  margin-top: 10px;
  margin-bottom: 10px;
}

.drawerHeaderContainer {
  display: flex;
  flex-direction: column;
  background: transparent;
  height: auto;
  margin-top: 10%;
  margin-bottom: 2%;
}

.drawerHeaderContainer > a {
  margin-left: auto;
  margin-right: auto;
}

.headerContainer {
  @include header;
  a,
  p {
    @include headerText;
  }
  margin-top: 1%;

  .firstChild {
    @include layout(row, flex-start, center);
    width: 20%;

    img {
      width: 100%;
      height: 100%;
      max-height: 15vh;
    }
  }

  .secondChild {
    @include layout(row, space-evenly, center);
    min-width: 10%;
    width: 70%;

    a {
      text-decoration: none;
      font-size: 125%;
      .antd-wave-shadow-color {
        color: $black;
      }
      &:hover {
        text-decoration: none;
        color: $black;
      }
    }
  }

  .thirdChild {
    @include layout(row, space-between, center);
    margin-right: 2%;
    max-width: 30%;

    .lngSelect {
      margin-right: 3%;
      width: 90px !important;
    }

    a {
      text-decoration: none;
      font-size: 120%;
      border: none;
      .antd-wave-shadow-color {
        color: $black;
      }
      &:hover {
        text-decoration: none;
        color: $black;
        border:none;
      }
    }
  }

  .navBar {
    display:none;
  }
}

@media only screen and (max-width: 1215px) {
  .headerContainer {
    @include header;

    .firstChild {
      @include layout(row, flex-start, center);
      width: 30%;

      img {
        height: 100%;
        width: 100%;
        max-height: 10vh;
        margin-left: 10%;
      }
    }

    .secondChild {
      display: none;
    }

    .thirdChild {
      display: none;
    }

    .navBar {
      @include navBar;
    }
  }
}